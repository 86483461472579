(function ($) {
    $(document).on('scroll', function () {
        value = $(window).scrollTop();

        if (value >= 100) {
            $('.site-header').addClass('shrink');
        } else {
            $('.site-header').removeClass('shrink');
        }
    });

    // handle aria-expanded attr on top header dropdowns
    $('#primary-menu .menu-item-has-children').hover(
        function () {
            $(this).children('a').attr('aria-expanded', 'true');
        },
        function () {
            $(this).children('a').attr('aria-expanded', 'false');
        }
    );

    // Mobile menu trigger
    $('.navbar-toggler').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('open');
        $('html').toggleClass('nav-open');
        $('.website-mobile-menu').fadeToggle();
    });

    // OneTrust add onclick event
    $('.ot-sdk-show-settings-footer').on('click', function (e) {
        OneTrust.ToggleInfoDisplay();
    });

    // attach className to element when it appears into view
    const inViewport = (entries, observer) => {
        entries.forEach((entry) => {
            entry.target.classList.toggle(
                'is-inViewport',
                entry.isIntersecting
            );
        });
    };

    const Obs = new IntersectionObserver(inViewport);

    // Attach observer to every [data-inviewport] element:
    const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
    ELs_inViewport.forEach((EL) => {
        Obs.observe(EL);
    });

    // Tabs handler
    $('.tabs li button').click(function () {
        // Active state for tabs
        $('.tabs li button').removeClass('active');
        $(this).addClass('active');
        // Active state for Tabs Content
        $('.tab_content_container > .active').removeClass('active');
        // console.log($(this).data('rel'));
        $($(this).data('rel')).addClass('active');
        $($(this).data('rel')).find('.reviews-carousel').slick('refresh');
    });

    // Hero carousel handler
    if ($('.hero-carousel .hero-carousel-slider').length) {
        $('.hero-carousel .hero-carousel-slider').slick({
            adaptiveHeight: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            cssEase: 'linear',
            fade: true,
            speed: 400,
        });
    }

    // Infinite LOOP START
    (function ($, window, document, undefined) {
        // Create the defaults once
        var pluginName = 'marquee',
            defaults = {
                enable: true, //plug-in is enabled
                direction: 'vertical', //vertical : horizontal
                itemSelecter: 'li',
                delay: 3000,
                speed: 1,
                timing: 1,
                mouse: true,
            };

        function Widget(element, options) {
            this.element = element;
            this.settings = $.extend({}, defaults, options);
            this._defaults = defaults;
            this._name = pluginName;
            this.version = 'v1.0';
            this.$element = $(this.element);
            this.$wrapper = this.$element.parent();
            this.$items = this.$element.children(this.settings.itemSelecter);

            this.next = 0;
            this.timeoutHandle;
            this.intervalHandle;

            if (!this.settings.enable) return;
            this.init();
        }

        Widget.prototype = {
            init: function () {
                var that = this;

                var totalSize = 0;

                $.each(this.$items, function (index, element) {
                    totalSize += that.isHorizontal()
                        ? parseInt($(element).outerWidth())
                        : parseInt($(element).outerHeight());
                });

                var elmentTotalSize = this.isHorizontal()
                    ? this.$element.outerWidth
                    : this.$element.outerHeight;

                if (totalSize < elmentTotalSize) return;

                this.$wrapper.css({
                    position: 'relative',
                    overflow: 'hidden',
                });

                this.$element.css({
                    position: 'absolute',
                    top: 0,
                    left: 0,
                });

                this.$element.css(
                    this.isHorizontal() ? 'width' : 'height',
                    '1000%'
                );

                this.cloneAllItems();

                if (this.settings.mouse) this.addHoverEvent(this);

                this.timer(this);
            },

            timer: function (that) {
                this.timeoutHandle = setTimeout(function () {
                    that.play(that);
                }, this.settings.delay);
            },

            play: function (that) {
                this.clearTimeout();
                var target = 0;
                for (var i = 0; i <= this.next; i++) {
                    target -= this.isHorizontal()
                        ? parseInt($(this.$items.get(this.next)).outerWidth())
                        : parseInt($(this.$items.get(this.next)).outerHeight());
                }
                this.intervalHandle = setInterval(function () {
                    that.animate(target);
                }, this.settings.timing);
            },

            animate: function (target) {
                var mark = this.isHorizontal() ? 'left' : 'top';
                var present = parseInt(this.$element.css(mark));
                if (present > target) {
                    if (present - this.settings.speed <= target) {
                        this.$element.css(mark, target);
                    } else {
                        this.$element.css(mark, present - this.settings.speed);
                    }
                } else {
                    this.clearInterval();
                    if (this.next + 1 < this.$items.length) {
                        this.next++;
                    } else {
                        this.next = 0;
                        this.$element.css(mark, 0);
                    }
                    this.timer(this);
                }
            },

            isHorizontal: function () {
                return this.settings.direction == 'horizontal';
            },

            cloneAllItems: function () {
                this.$element.append(this.$items.clone());
            },

            clearTimeout: function () {
                clearTimeout(this.timeoutHandle);
            },

            clearInterval: function () {
                clearInterval(this.intervalHandle);
            },

            addHoverEvent: function (that) {
                this.$wrapper
                    .mouseenter(function () {
                        that.clearInterval();
                        that.clearTimeout();
                    })
                    .mouseleave(function () {
                        that.play(that);
                    });
            },
        }; //prototype

        $.fn[pluginName] = function (options) {
            // chain jQuery functions
            return this.each(function () {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(
                        this,
                        'plugin_' + pluginName,
                        new Widget(this, options)
                    );
                }
            });
        };
    })(jQuery, window, document);
    // Infinite LOOP END

    //init Facts Carousel START
    if ($('.facts-carousel').length) {
        $('.facts-carousel').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            nextArrow: '.factsSliderNextCtrl',
            prevArrow: '.facsSliderPrevCtrl',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }
    //init Facts Carousel END

    // Marquee Sliders START
    let marqueeSliders = document.querySelectorAll('.img-carousel');
    // Options
    const marqueeSliderOptions = {
        accessibility: true,
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        selectedAttraction: 0.015,
        friction: 0.25,
    };

    marqueeSliders.forEach((slider) => {
        let sliderTicker = new Flickity(slider, marqueeSliderOptions);

        sliderTicker.x = 0;
        // Play
        sliderTicker.play = function () {
            // Set the decrement of position x
            sliderTicker.x -= 1.5;
            // Settle position into the slider
            sliderTicker.settle(sliderTicker.x);
            // Set the requestId to the local variable
            sliderTicker.requestId = window.requestAnimationFrame(
                sliderTicker.play
            );
        };

        // Pause
        sliderTicker.pause = function () {
            // console.log(sliderTicker.requestId))
            if (sliderTicker.requestId) {
                // Cancel the animation
                window.cancelAnimationFrame(sliderTicker.requestId);
                // Reset the requestId for the next animation.
                sliderTicker.requestId = undefined;
            }
        };

        slider.addEventListener('mouseenter', sliderTicker.pause);
        slider.addEventListener('mouseleave', sliderTicker.play);

        sliderTicker.play();
    });
    // Marquee Sliders END

    $.fn.scrollCenter = function (elem, speed) {
        var active = $(this).find(elem);
        var activeWidth = active.width() / 2;
        var pos = active.position().left + activeWidth + 16;
        var elpos = $(this).scrollLeft();
        var elW = $(this).width();
        pos = pos + elpos - elW / 2;

        $(this).animate(
            {
                scrollLeft: pos,
            },
            speed == undefined ? 1000 : speed
        );
        return this;
    };

    if ($('#viewContainer').length) {
        $('#viewContainer').scrollCenter('.inView', 100);
    }

    // Init Plans Cards Slider START
    $('.plans-carousel').each(function (index, element) {
        var plansSlider = $(this);
        // Set initial slide to center on small screens
        var initialSlide = plansSlider.find('[data-initialslide]');

        plansSlider.slick({
            initialSlide: initialSlide.length
                ? $(initialSlide).data('initialslide')
                : 0,
            centerMode: true,
            variableWidth: true,
            centerPadding: '60px',
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            infinite: false,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 992,
                    settings: 'unslick',
                },
            ],
        });
    });
    // Init Plans Cards Slider END

    // Init reviews SLider/Sliders START
    $('.reviews-carousel').each(function (index, element) {
        var slider = $(this);

        slider.slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            centerPadding: '50px',
            speed: 1000,
            nextArrow: slider.prev('.controls').find('.sliderNextCtrl'),
            prevArrow: slider.prev('.controls').find('.sliderPrevCtrl'),
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 500,
                    },
                },
            ],
        });
    });
    // Init reviews SLider/Sliders START

    // Youtube embeded videos handler START
    //generates random id helper
    function guid() {
        return '_' + Math.random().toString(36).substr(2, 9);
    }

    function generateIframe(div) {
        var playerElements = document.getElementsByClassName('js-play-video');
        for (var n = 0; n < playerElements.length; n++) {
            if (
                playerElements[n].querySelector('.slide-overlay').id !== div.id
            ) {
                var playerElement = playerElements[n].querySelector('iframe');
                var playerOverlay =
                    playerElements[n].querySelector('.slide-overlay');
                if (
                    typeof playerElement != 'undefined' &&
                    playerElement != null
                ) {
                    playerElement.remove();
                    playerOverlay.style.display = 'flex';
                }
            } else {
                var iframe = document.createElement('iframe');
                iframe.setAttribute(
                    'src',
                    'https://www.youtube.com/embed/' +
                        div.dataset.id +
                        '?feature=oembed&autoplay=1'
                );
                iframe.setAttribute('frameborder', '0');
                iframe.setAttribute('allowfullscreen', '1');
                iframe.setAttribute(
                    'allow',
                    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                );
                div.parentNode.appendChild(iframe);
                div.style.display = 'none';
            }
        }
    }

    function initYouTubeVideos() {
        var playerElements = document.getElementsByClassName('js-play-video');
        for (var n = 0; n < playerElements.length; n++) {
            var videoId = playerElements[n].dataset.id;
            var div = document.createElement('div');
            div.id = guid();
            div.setAttribute('data-id', videoId);
            div.setAttribute('class', 'slide-overlay');
            div.setAttribute(
                'style',
                '--bg-img:url(' + playerElements[n].dataset.thumbnail + ')'
            );
            var videoTitle = document.createElement('h4');
            videoTitle.innerText = playerElements[n].dataset.heading;
            div.appendChild(videoTitle);
            div.onclick = function () {
                generateIframe(this);
            };
            playerElements[n].appendChild(div);
        }
    }
    // Inject Youtube embeded videos after Page load
    document.addEventListener('DOMContentLoaded', initYouTubeVideos);
    // Youtube embeded videos handler END

    // Videos Carousel init
    if ($('.slider-container').length) {
        $('.slider-container').slick({
            infinite: false,
            slidesToShow: 1,
            nextArrow: '.sliderNextBtn',
            prevArrow: '.sliderPrevBtn',
        });
    }

    // FAQ accordion
    $('.popular-faqs_question_title').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        // $(this).next().toggle();
        $(this).next().stop().slideToggle(500);
    });

    // Footer mobile accordian
    $('.menu-col_header').on('click', function (e) {
        $(this).parent('.menu-col').toggleClass('active');
    });

    // Background transition start
    function transitionBgColor() {
        var sections = [...document.getElementsByTagName('section')];
        var mainElem = document.getElementById('primary');

        // Set background color outside of scrolling action to bg of first section to support first section color without user scroll
        mainElem.style.backgroundColor = sections[0].dataset.bgColor;

        window.addEventListener('scroll', function () {
            var scrollFromTop = window.pageYOffset;
            var winHeight = window.innerHeight;

            for (let i = 0; sections.length > i; i++) {
                var elemFromTop = sections[i].offsetTop;
                var elemHeight = sections[i].offsetHeight;

                if (scrollFromTop + winHeight <= elemFromTop + elemHeight) {
                    mainElem.style.backgroundColor =
                        sections[i].dataset.bgColor;
                    break;
                }
            }
        });
    }

    // Check for flexible blocks page template, then run transition script
    if (
        document.body.classList.contains('page-template-flexible-blocks-page') |
        document.body.classList.contains(
            'page-template-paid-flexible-blocks-page'
        )
    ) {
        transitionBgColor();
    }

    // EOF Background transition

    // Prices toggle
    $('.plans-items').on('change', '.js-toggle-prices', function () {
        var $checkBox = $(this);

        // @todo Update the link using .attr
        // $('.plans-item_action a').each(function() {
        //   if($checkBox.is(':checked')) {
        //     this.href = this.href.replace('month', 'year');
        //   } else {
        //     this.href = this.href.replace('year', 'month');
        //   }
        // });

        // change prices and period
        $('.js-price-change').each(function () {
            if ($checkBox.is(':checked')) {
                $(this).text($(this).data('monthly'));
            } else {
                $(this).text($(this).data('annually'));
            }
        });
    });

    //Start - Amplitude Tracking
    if (typeof amplitude !== 'undefined') {
        // Define Environments
        let envKey =
            location.origin == 'https://www.life360.com'
                ? 'cbd6118189c81a8480e372979a8005ff'
                : '2a5ce516d3111a46b30ae3b56a0ceec3';
        let apiEndpoint =
            location.origin == 'https://www.life360.com'
                ? 'amplitude.life360.com'
                : 'amplitude.qa.life360.com';
        // Amplitude Initialize
        amplitude.getInstance().init(envKey, undefined, {
            apiEndpoint: apiEndpoint,
            includeUtm: true,
            includeReferrer: true,
        });

        // send the Identify call
        let identify = new amplitude.Identify().setOnce(
            'intial_referrer',
            document.referrer
        );
        amplitude.getInstance().identify(identify);

        // define amplitude events to track
        const PAGE_LOAD_EVENT = 'web-cms-page-visited',
            BUTTON_CLICK_EVENT = 'web-plan-period-selected',
            DOWNLOAD_BUTTON_CLICK_EVENT = 'web-app-download';

        // Collecting page parameters to track in Amplitude
        let params = {
            referrer: `${document.referrer}`,
            referring_domain: document.referrer
                ? document.referrer.split('/')[2]
                : '',
            page_title: document.title,
            page_url: location.href,
            page_path: location.pathname,
        };

        // Collecting url query string parameters to track in Amplitude
        const queryString = window.location.search;
        // Check webview template
        const bodyHasWebViewClass = document.body.classList.contains(
            'page-template-web-view-page'
        );

        const urlParams = new URLSearchParams(queryString);
        if (queryString) {
            urlParams.forEach(function (value, key) {
                params[key] = value;
            });
        }

        // Check local storage
        let sessionData = JSON.parse(
            localStorage.getItem('SESSION_URL_PARAMS')
        );

        // Handle device ID (legacy). @TODO refactor device_id handle
        let actualDeviceId = '';
        if (
            sessionData &&
            sessionData.device_id &&
            sessionData.device_id != undefined
        ) {
            actualDeviceId = sessionData.device_id;
        } else if (params.device_id && params.device_id != undefined) {
            actualDeviceId = params.device_id;
        }

        if (actualDeviceId) {
            amplitude.getInstance().setDeviceId(actualDeviceId);
            params.device_id = actualDeviceId;
        }
        // Set localstorage if not set
        if (!sessionData) {
            localStorage.setItem('SESSION_URL_PARAMS', JSON.stringify(params));
        }
        // Log PAGE_LOAD_EVENT
        amplitude.getInstance().logEvent(PAGE_LOAD_EVENT, params, () => {
            params.device_id = amplitude.getInstance().options.deviceId;
        });

        function changeUrl(element) {
            $('.plans-membership-btn').each(function (index) {
                if (element.checked) {
                    var newLink = $(this).attr('href').replace('year', 'month');
                    if (!/[?&]device_id\s*=/.test(newLink)) {
                        newLink += '&device_id=' + params.device_id;
                    }
                    $(this).attr('href', newLink);
                } else {
                    var newLink = $(this).attr('href').replace('month', 'year');
                    if (!/[?&]device_id\s*=/.test(newLink)) {
                        newLink += '&device_id=' + params.device_id;
                    }
                    $(this).attr('href', newLink);
                }
            });
        }

        $('.js-toggle-prices[type="checkbox"]').on('click', function (element) {
            changeUrl(element.target);
            params.period = element.target.checked ? 'month' : 'year';
            localStorage.setItem('SESSION_URL_PARAMS', JSON.stringify(params));
        });

        $('a[href*="period="]').on('click', function (button) {
            function getURLParameter(url, name) {
                return (RegExp(name + '=' + '(.+?)(&|$)').exec(url) || [
                    ,
                    null,
                ])[1];
            }

            var url = $(this).attr('href');
            var plan = getURLParameter(url, 'plan');
            var period = getURLParameter(url, 'period');
            var web_loc = getURLParameter(url, 'web_loc');

            params.plan = plan;
            params.period = period;
            params.web_loc = web_loc;

            localStorage.setItem('SESSION_URL_PARAMS', JSON.stringify(params));
            // sessionStorage.setItem(SESSION_URL_PARAMS, JSON.stringify(params));
            // var toggleStatus = params.period == 'month' ? false : true;
            // We will want to clean this up. Hotfix to get this working for places without toggle
            if (period !== 'month') {
                changeUrl(button);
            } else {
                var newLink = $(this).attr('href');
                if (!/[?&]device_id\s*=/.test(newLink)) {
                    newLink += '&device_id=' + params.device_id;
                }
                $(this).attr('href', newLink);
            }

            amplitude.getInstance().logEvent(BUTTON_CLICK_EVENT, params);
        });

        $('.app-download-btn').on('click', function (button) {
            amplitude
                .getInstance()
                .logEvent(DOWNLOAD_BUTTON_CLICK_EVENT, params);
        });

        // Web-View pages Amplitude tracking START
        if (bodyHasWebViewClass) {
            if (
                urlParams.has('user-id') &&
                urlParams.has('circle-id') &&
                urlParams.has('trigger')
            ) {
                var userId = urlParams.get('user-id');
                amplitude.getInstance().setUserId(userId);

                let webViewProps = {
                    'user-id': urlParams.get('user-id'),
                    'circle-id': urlParams.get('circle-id'),
                    trigger: urlParams.get('trigger'),
                };
                // Web-View page viewed
                amplitude
                    .getInstance()
                    .logEvent('gwm-web-viewed', webViewProps);

                let webViewBtn = document.getElementById('webview-action-btn');
                if (document.body.contains(webViewBtn)) {
                    webViewBtn.addEventListener('click', function (e) {
                        e.preventDefault();
                        // amplitude log btn click, callback for href default
                        amplitude
                            .getInstance()
                            .logEvent(
                                'gwm-web-upgrade-tapped',
                                webViewProps,
                                function () {
                                    window.location =
                                        webViewBtn.getAttribute('href');
                                }
                            );
                    });
                }
            }
        }
        // Web-View pages Amplitude tracking END
    }
    //End - Amplitude Tracking

    // Featured Phone carousel
    if ($('.phone-slider .screen').length) {
        $('.fade').slick({
            dots: false,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
        });

        $('.phone-slider_controls').on('click', 'li', function (e, slick) {
            $(this)
                .siblings('li')
                .removeClass('active')
                .end()
                .addClass('active');
            var slideIndex = $(this).index();
            $('.fade').slick('slickGoTo', slideIndex);
        });
    }

    // Smooth scrolling to anchor links START
    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // Figure out element to scroll to
            var target = $(this.hash);
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate(
                    {
                        scrollTop: target.offset().top - 64, // header height scrolling state
                    },
                    1000,
                    function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(':focus')) {
                            // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        }
                    }
                );
            }
        });
    // Smooth scrolling to anchor links END

    // Grab Membership Banner's next element and add class that has padding-top
    $('.membership-banner.with-bottombar')
        .next()
        .addClass('membership-header-extra-space');
    // EOF Membership Banner style

    // Modal with timer START
    const modal = document.getElementById('modalPromo');

    if (modal !== null) {
        const triggerType = modal.dataset.trigger;
        const triggerTime = modal.dataset.delay;
        const closeEls = document.querySelectorAll('[data-close]');
        const htmlElm = document.querySelector('html');
        const isVisible = 'is-visible';

        const setCookie = (name, value, days) => {
            let expires = '';
            if (days) {
                let date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                expires = '; expires=' + date.toUTCString();
            }
            document.cookie = name + '=' + (value || '') + expires + '; path=/';
        };

        const getCookie = (name) => {
            let nameEQ = name + '=';
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0)
                    return c.substring(nameEQ.length, c.length);
            }
            return null;
        };

        const showModal = () => {
            if (getCookie('modalShown') !== 'true') {
                modal.classList.add(isVisible);
                htmlElm.classList.add(isVisible);

                setCookie('modalShown', 'true', 1);
            }
        };

        const mouseExitEvent = (e) => {
            const shouldShowExitIntent =
                !e.toElement && !e.relatedTarget && e.clientY < 10;

            if (shouldShowExitIntent) {
                document.removeEventListener('mouseout', mouseExitEvent);
                showModal();
            }
        };

        switch (triggerType) {
            case 'time_delay':
                setTimeout(showModal, triggerTime);
                break;
            case 'exit_intent':
                // check is touchscreen browsers
                let isTouch =
                    'ontouchstart' in window || navigator.msMaxTouchPoints > 5;
                if (isTouch) {
                    setTimeout(showModal, triggerTime); // show delay for touch screens
                } else {
                    document.addEventListener('mouseout', mouseExitEvent); // exit intent show for desktops
                }
                break;
        }

        for (const el of closeEls) {
            el.addEventListener('click', function () {
                this.parentElement.parentElement.parentElement.classList.remove(
                    isVisible
                );
                htmlElm.classList.remove(isVisible);
            });
        }
        document.addEventListener('click', (e) => {
            if (e.target == document.querySelector('.modal.is-visible')) {
                document
                    .querySelector('.modal.is-visible')
                    .classList.remove(isVisible);
                htmlElm.classList.remove(isVisible);
            }
        });
        document.addEventListener('keyup', (e) => {
            // ESC key close Modal
            if (
                e.key == 'Escape' &&
                document.querySelector('.modal.is-visible')
            ) {
                document
                    .querySelector('.modal.is-visible')
                    .classList.remove(isVisible);
                htmlElm.classList.remove(isVisible);
            }
        });
    }
    // Modal with timer END

    // Content Hub Featured Articles sidebar fix on scroll
    $.fn.scrollFixed = function (params) {
        params = $.extend({ hideBeforeDiv: 0 }, params);
        var element = $(this);
        var elwidth = element.outerWidth();
        var distanceTop = element.offset().top - 94; // scrolled header Height + spacing

        if (params.hideBeforeDiv) {
            var bottom =
                $(params.hideBeforeDiv).offset().top -
                element.outerHeight(true) -
                94;
        } else {
            var bottom = 200000;
        }
        $(window).scroll(function () {
            if (
                $(window).scrollTop() > distanceTop &&
                $(window).scrollTop() < bottom
            ) {
                element.css({
                    position: 'fixed',
                    top: '5.875rem',
                    width: elwidth,
                });
            } else {
                element.css({ position: 'static', width: 'auto' });
            }
        });
    };

    $(window).on('load resize', function () {
        if ($(window).width() > 992 && $('.hub-article-toc').length) {
            $('.hub-article-toc').scrollFixed({
                hideBeforeDiv: '.site-footer',
            });
        }
    });

    // Press Page infinite load more
    $('.press-grid').on('click', '.js-loadmore-press', function (e) {
        var button = $(this),
            currentPage = button.data('page'),
            posttype = button.data('posttype'),
            btnTitle = button.text();
        data = {
            action: 'loadmorepress',
            page: currentPage,
            posttype: posttype,
            btntitle: btnTitle,
            security: life360Ajax.security,
        };

        $.ajax({
            url: life360Ajax.ajaxurl, // AJAX handler
            data: data,
            type: 'POST',
            beforeSend: function () {
                button.text('Loading...'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
                if (data) {
                    button.text(btnTitle).parent().parent().append(data);
                    button.remove();
                    currentPage++;
                } else {
                    button.remove();
                }
            },
        });
    });

    // Image+Text Slider START
    if ($('.slider-wrap').length) {
        var $mainSlider = $('.slider-nav'),
            $secondarySlider = $('.slider-for');
        ($sliderFrame = $('#sliderFrame svg path')),
            ($frameColors = [
                '#FF8C00',
                '#C1A6FF',
                '#6BD2B0',
                '#85C8DB',
                '#C1A6FF',
                '#006E5A',
                '#092C45',
            ]);
        $secondarySlides = $('.slider-for > div');

        $mainSlider.on('init', function (event, slick) {
            $('.slider-counter').append(
                '<div class="slick-counter d-flex align-items-center"><span class="current mr-3"></span><div class="slider-progress"><div class="progress"></div></div><span class="total ml-3"></span></div>'
            );
            $('.current').text('0' + (slick.currentSlide + 1));
            $('.total').text(
                slick.slideCount < 10
                    ? '0' + slick.slideCount
                    : slick.slideCount
            ); // prepend number with 0 if less than a 10
        });
        $mainSlider.on(
            'beforeChange',
            function (event, slick, currentSlide, nextSlide) {
                $('.current').text(
                    nextSlide + 1 < 10 ? '0' + (nextSlide + 1) : nextSlide + 1
                ); // prepend number with 0 if less than a 10
            }
        );

        $mainSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: $secondarySlider,
            fade: true,
        });

        $secondarySlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: $mainSlider,
        });

        // Slider Progressbar Start
        var time = 3,
            $bar,
            tick,
            percentTime;

        $bar = $('.slider-progress .progress');

        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            tick = setInterval(interval, 15);
        }

        function interval() {
            percentTime += 1 / (time + 0.1);
            $bar.css({
                width: percentTime + '%',
            });
            if (percentTime >= 100) {
                $mainSlider.slick('slickNext');
            }
        }

        function resetProgressbar() {
            $bar.css({
                width: 0 + '%',
            });
            clearTimeout(tick);
        }

        // Reset Progressbar When Slide Change
        $mainSlider.on('afterChange', function (event, slick, currentSlide) {
            startProgressbar();
        });

        startProgressbar();
        // Slider Progressbar End
    }
    // Image+Text Slider END

    // Open Roles START
    // convert string to slug format / filter utility
    function slugify(string) {
        return string
            .toString()
            .trim()
            .toLowerCase()
            .replace(/\s+/g, '-')
            .replace(/[^\w\-]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
    }

    if ($('#positions-list').length) {
        if (location.hash) {
            setTimeout(function () {
                window.scrollTo(0, 0);
            }, 1);
            var $jobDeepLink = location.hash;
        }

        var settings = {
            async: true,
            crossDomain: true,
            url: 'https://boards-api.greenhouse.io/v1/boards/life360/jobs?content=true',
            method: 'GET',
        };

        $.ajax(settings).done(function (response) {
            var openJobs = response.jobs;
            var $content = $('#positions-list');
            var $locationSelect = $('#location-filter .drop-selector-body');
            var $departmentSelect = $('#department-filter');
            var $jobResults =
                '<p class="openroles-empty">No openings found.</p>';
            var locations = [];
            var departments = [];

            $.each(openJobs, function (i, job) {
                var $jobAnchor = slugify(job.title);
                var $jobLocation = job.location.name;
                var $jobDepartment = job.departments[0].name;

                if ($.inArray($jobLocation, locations) === -1) {
                    locations.push($jobLocation);
                }

                if ($.inArray($jobDepartment, departments) === -1) {
                    departments.push($jobDepartment);
                }

                $jobResults +=
                    '<a href="' +
                    job.absolute_url +
                    '" target="_blank" id="' +
                    $jobAnchor +
                    '" data-department-filter="' +
                    slugify($jobDepartment) +
                    '" data-location-filter="' +
                    slugify($jobLocation) +
                    '" class="positions-list-item"><h2>' +
                    job.title +
                    '</h2><p class="mb-0">This job is based out of ' +
                    $jobLocation +
                    '</p></a>';
            });

            $.each(locations, function (i, location) {
                $locationSelect.append(
                    '<label>' +
                        location +
                        '<input type="radio" name="location-filter" value="' +
                        slugify(location) +
                        '" data-value="' +
                        location +
                        '"></label>'
                );
            });

            $.each(departments, function (i, dep) {
                var jobsCount = openJobs.filter(
                    (obj) => obj.departments[0].name === dep
                ).length;
                $departmentSelect.append(
                    '<li><input type="radio" id="customSelectList-' +
                        i +
                        '" value="' +
                        slugify(dep) +
                        '" name="department-filter"><label for="customSelectList-' +
                        i +
                        '">' +
                        dep +
                        ' (' +
                        jobsCount +
                        ')</label></li>'
                );
            });

            $('.all-deps span').text(Object.keys(openJobs).length);

            $content.html($jobResults);

            if ($jobDeepLink) {
                $('html,body').animate(
                    { scrollTop: $($jobDeepLink).offset().top - 100 },
                    'fast'
                );
                $($jobDeepLink).addClass('open');
            }
        });

        // Default dropdown action to show/hide dropdown content
        $('.js-drop-action').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('js-open');
            $(this).parent().find('.drop-selector-body').toggleClass('js-open');
        });

        $('#location-filter').on('click', 'label', function () {
            $('.drop-selector-body, .js-drop-action').removeClass('js-open');
        });

        $('#location-filter').on('change', 'input[type="radio"]', function () {
            var value = $(
                '.drop-selector-body input[type="radio"]:checked'
            ).data('value');
            $('.js-value').text(value);
        });

        // Filtering
        $('.openroles-filters').on('change', 'input[type=radio]', function () {
            var jobs_filters = [];

            $('.openroles-filters input[type=radio]:checked').each(function () {
                if ($(this).val() != 0) {
                    jobs_filters[$(this).attr('name')] = $(this).val();
                }
            });

            $('.openroles-empty').hide();
            $('#positions-list .positions-list-item')
                .hide()
                .filter(function () {
                    var show = true;
                    for (var filter in jobs_filters) {
                        show =
                            show &&
                            $(this).data(filter) == jobs_filters[filter];
                    }
                    return show;
                })
                .show();

            if (!$('#positions-list > a:visible').length) {
                $('.openroles-empty').show();
            }
        });
    }
    // Open Roles END

    // Nav TABS functionality for Member protection page banner START
    function convertToSlug(Text) {
        return Text.trim()
            .toLowerCase()
            .replace(/[^a-z0-9-]/gi, '-')
            .replace(/-+/g, '-')
            .replace(/^-|-$/g, '');
    }

    function generateNavTabs() {
        let tocStr =
            '<ul class="d-flex flex-column flex-lg-row justify-content-between">';
        let subtitles = $('.resources-page-content h2');
        if (!subtitles.length) return;
        for (let i = 0; i < subtitles.length; i++) {
            subtitles[i].setAttribute(
                'id',
                convertToSlug(subtitles[i].textContent)
            );
            tocStr +=
                '<li><a href="#' +
                convertToSlug(subtitles[i].textContent) +
                '">' +
                subtitles[i].textContent +
                '</a></li>';
        }
        tocStr += '</ul>';
        $('.resources-nav-tabs').append(tocStr);
    }

    if ($('body').hasClass('page-template-misuse-protecting-page')) {
        generateNavTabs();
    }
    // Smooth Scroll to title anchor
    // Select all Tabs links with hashes
    $('.resources-nav-tabs a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') ==
                    this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                let target = $(this.hash);
                target = target.length
                    ? target
                    : $('[name=' + this.hash.slice(1) + ']');
                let scrollOffset = $('.site-header').height() + 50;
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate(
                        {
                            scrollTop: target.offset().top - scrollOffset,
                        },
                        800,
                        function () {
                            // Callback after animation
                            // Must change focus!
                            let $target = $(target);
                            $target.trigger('focus');
                            if ($target.is(':focus')) {
                                // Checking if the target was focused
                                return false;
                            } else {
                                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                                $target.focus(); // Set focus again
                            }
                        }
                    );
                }
            }
        });
    // Nav TABS functionality for Member protection page banner END

    // hide form after sucees submit
    document.addEventListener(
        'wpcf7mailsent',
        function (event) {
            document
                .querySelectorAll(
                    'form.wpcf7-form > :not(.wpcf7-response-output)'
                )
                .forEach((el) => {
                    el.style.display = 'none';
                });
        },
        false
    );
})(jQuery);
